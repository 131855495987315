<!--  -->
<template>
<div class=''>
    <div>
    <video ref="videoPlayer" controls class="video-player">
      <source :src="videoSrc" type="video/mp4">
    </video>
    <div class="button-container">
      <button class="play-button" @click="play">播放</button>
      <button class="pause-button" @click="pause">暂停</button>
    </div>
  </div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
    videoSrc: require("@/assets/video/healthPolularization.mp4"),
    };
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    play() {
      this.$refs.videoPlayer.play();
    },
    pause() {
      this.$refs.videoPlayer.pause();
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.video-container {
  position: relative;
}

.video-player {
  width: 90%;
  height: auto;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-button,
.pause-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #3498db;
  border: none;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.play-button:hover,
.pause-button:hover {
  background-color: #2980b9;
}
</style>